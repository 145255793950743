import { Component, Input, OnInit } from '@angular/core';
import { NodeRepresentation } from '../../../interfaces/node-representation.interface';
import { Observable } from 'rxjs';
import { NodeConfig } from '../../../models/node/node-type.model';
import { ExcelChartNode } from '../../../models/node/excel-chart-node.model';
import { PropertyEditorComponent } from '../../property-editor/property-editor.component';
import { SectionsQuery } from '../../../state/sections/sections.query';
import { SideNavService } from '../../../../../modules/core/services/side-nav.service';

@Component({
    selector: 'elias-editor-display-excel-chart',
    templateUrl: './display-excel-chart.component.html',
})
export class DisplayExcelChartComponent implements NodeRepresentation, OnInit {
    @Input() config?: NodeConfig;
    @Input() content = '';
    @Input() node?: ExcelChartNode;
    @Input() nodeViewModel?: Observable<ExcelChartNode>;

    get hasExcelAndWorksheetAssigned(): boolean {
        return !!(this.node && this.node.excelAsset && this.node.excelWorksheet);
    }

    get isOutdated(): boolean {
        return !!this.node?.outdated;
    }

    constructor(private sectionsQuery: SectionsQuery, private sideNavService: SideNavService) {}

    ngOnInit(): void {
        if (!this.node) {
            return;
        }

        if (this.node.editing && !this.hasExcelAndWorksheetAssigned) {
            const inputs = {
                sectionOrNodeType: 'node',
                sectionId: this.sectionsQuery.getActiveId(),
                nodeId: this.node.id,
            };

            const outputs = {};
            this.sideNavService.setComponent(PropertyEditorComponent, inputs, outputs);
        }
    }
}
