<elias-status-message type="warning" *ngIf="isOutdated; else excelChart">
    {{ 'error.excelChart.outdated' | translate }}
</elias-status-message>

<ng-template #excelChart>
    <elias-editor-display-chart
        *ngIf="hasExcelAndWorksheetAssigned; else noWorksheetAssigned"
        [nodeViewModel]="nodeViewModel"
        [config]="config"
        [node]="node"
        [content]="content"></elias-editor-display-chart>
</ng-template>

<ng-template #noWorksheetAssigned>
    <elias-status-message type="warning">
        {{ 'error.excelChart.noWorksheetAssigned' | translate }}
    </elias-status-message>
</ng-template>
